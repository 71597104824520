@import url('https://fonts.cdnfonts.com/css/roboto');
@import url('https://fonts.cdnfonts.com/css/play');

body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  margin: 0;
  padding: 0;
  font-size: 20px;

  font-family: "Roboto", serif;
  background-color: white;

  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

.renderNewLine {
  white-space: pre-line;
  // text-align: center;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0 !important;
}

.fill-parent-width {
  width: 100%;
}

svg.leaflet-attribution-flag {
  display: none !important;
}
